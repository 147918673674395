.display_review_flex_center {
  display: flex;
  min-height: 0px;
  justify-content: space-between;
}

.display_review_flex_center.columns {
  flex-direction: column;
}

.display_review_flex_center > div {
  font-size: 14px;
  color: #424242;
  text-align: left;
  line-height: 21px;
}

.display_review_flex_center > div.group-question {
  font-size: 28px;
  font-weight: bold;
  width: 75% !important;
  line-height: 1.5;
}

.display_bold {
  font-weight: bold;
}

.display_normal {
  font-weight: normal;
}

.display_review_question {
  width: 50% !important;
  min-width: 50% !important;
  color: #424242;
}

@media (min-width: 320px) and (max-width: 480px) {
  .display_review_question {
    padding: 10px;
  }
  
}

.display_update_button {
 background-color: white;
 color: blue;
 border: none;
 margin-right: 10%;
}

.display_review_answer {
  margin-right: 10%;
  font-weight: normal;
  margin-left: 5%;
  color: #424242;
}

.display_review_answer.columns {
  padding-top: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .display_review_answer {
    margin: 0% 10% 0% 4%;
  }
}

.review-dotted-line-break {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  /* border-top: 2px dashed #b3b3b3; */
  height: 1px;
  color: transparent;
  margin-right: 10%;
}

.review-solid-line {
  margin-top: 10px !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
  color: transparent;
}

@media screen and (max-width: 767px) {
  .display_update_button {
    text-align: right;
    margin-top: 10px;
    margin-right: 0%;
  }

  .display_review_question {
    width: 100% !important;
    min-width: 100% !important;
  }
  
}