@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/ProximaNova-Regular.ttf);
}

@font-face {
  font-family: 'Proxima Nova Medium';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/proxima-nova-medium.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'OpenSans Semi Bold';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/Fonts/OpenSans-Italic.ttf);
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/Fonts/OpenSans-Bold.ttf);
}


body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: 'Open Sans';
}

#root {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
}

code {
  font-family: 'Courier'
}


@media all  and (-ms-high-contrast:none) {
  *::-ms-backdrop, .right-canvas-content-wrapper {
    height: unset;
    flex:auto
  }
}

.react-datepicker-popper {
  z-index: 9000 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}

[aria-live="polite"] {
  font-family: 'Open Sans' !important;
}