.Accordion {
  position: relative;
}

.Accordion.collapsed {
  user-select: none;
}

.Accordion.expanded .AccordionContent {
  height: inherit;
  overflow: inherit;
}

.Accordion.collapsed .AccordionContent {
  height: 0;
  overflow: hidden;
}

.Accordion .ChevronIcon {
  user-select: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.Accordion.expanded .ChevronIcon {
  transform: rotate(90deg);
}

.Accordion.collapsed .ChevronIcon {
  transform: rotate(-180deg);
}

.ChevronIcon {
  cursor: pointer;
}
