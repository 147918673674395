div.footer-container-root {
  background-color: #2d2d2d;
  color: rgb(153, 153, 153);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: auto;
  font-size: 14px;
}

footer.global-footer {
  max-width: 1440px;
  margin: auto;
}

footer.global-footer a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

footer.global-footer > h1 {
  margin: 0;
}

.contact-number {
  color: #d7d7d7;
  padding-top: 30px;
  font-size: 14px;
  font-weight: normal;
}

.header-information-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-media-details {
  margin: 10px 0;
  color: rgb(153, 153, 153);
  font-size: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  flex-wrap: wrap;
}

.social-media-link{
  margin: 0 10px;
  padding: 4px;
}

.social-media-link{
  margin: 0 10px;
  padding: 4px;
}

p {
  margin: 5px 0;
}

i {
  cursor: pointer;
}

.footer-text-container {
  font-size: 14px;
  line-height: 28px;
}

.footer-text-container a {
    color: #00529c !important;
    align-items: center;
}

.footer-agent-name-container {
    display: flex;
    flex-flow: row;
    color: #454545;
}

.global-footer a,
.global-footer i{
  color: inherit;
}

@media (max-width: 479px) {
  div.footer-container-root {
      padding: 20px;
  }
  .header-information-footer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      padding: 20px 0;
  }

  .social-media-details {
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding-bottom: 20px;
  }

  .social-media-details i {
      font-size: 35px;
      height: 30px;
      width: 30px;
  }
}

.footer-form-number {
  position: relative;
}

.form-number {
  position: absolute;
  top: -30px;
  left: 20px;
}

.divider {
    color: #d8d8d8;
    padding-left: 30px;
    padding-right: 30px;
}

.footer-links-container {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.footer-links {
    color: #00529c;
    text-align: left;
    display: flex;
}

.social-media-details>a {
    margin: 0 10px;
}

.alan-style {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #454545;
}

.footer-text-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #454545;
}

.disclaimer-text {
    color: #454545;
    line-height: 20px;
}

@media (max-width: 798px) {
  footer.global-footer {
      height: auto;
      position: relative;
  }
  .footer-form-number {
      margin-bottom: 0px;
      height: 20px;
      background: #ffffff;
  }
  .form-number {
      top: -10px;
  }
    .divider {
        color: transparent;
        padding-left: 0px;
        padding-right: 0px;
    }
    .footer-links-container {
        flex-flow: column;
        align-items: center;
    }
    .footer-agent-name-container {
        flex-flow: column;
        align-items: center;
    }
}